import Card, { CardTitle } from "components/Card";
import {
  cn,
  isNullEmptyOrWhitespace,
  tryInvalidateApiCache,
} from "helpers/common";
import {
  MenuItemSchema,
  menuMutateSchema,
  MenuMutateSchema,
  useMenuGetOneById,
  useMenuMutate,
} from "hooks/useMenu";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import { Input } from "components/placement/Input";
import Button from "components/placement/Button";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import {
  Control,
  FieldArrayWithId,
  useFieldArray,
  useForm,
} from "react-hook-form";
import React from "react";
import MenuItem from "./item";
import { Alert } from "components/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type MenuFormProps = {
  id?: string;
};

const MenuForm = ({ id }: MenuFormProps) => {
  const isNewForm = isNullEmptyOrWhitespace(id);
  const navigate = useNavigate();

  const {
    data: menuData,
    isLoading: isLoadingMenu,
    isFetched: isFetchedMenu,
    error: errorMenu,
  } = useMenuGetOneById({
    enabled: !isNewForm /* && isFetchedMenuForm*/,
    id: id ?? "",
  });

  const { mutate } = useMenuMutate({
    onSuccess: async () => {
      toast.success("Menu updated successfully.");

      await tryInvalidateApiCache("/api/menus-get");

      // TODO: Find a better way to refresh the page
      // We must do it this way due to architectural constraints
      // An alternative could be to use Jotai to store the menu data
      // and expose an invalidation function
      window.location.reload();
      navigate("./..");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<MenuMutateSchema>({
    defaultValues: {
      id: "",
      name: "",
      title: "",
      menuItems: [],
    },
    resolver: zodResolver(menuMutateSchema),
  });
  const {
    control,
    handleSubmit,
    reset,
    // formState: { errors },
  } = reactForm;

  React.useEffect(() => {
    if (menuData) {
      reset(menuData);
    }
  }, [menuData, reset]);

  if (
    // isLoadingMenuForm ||
    // !isFetchedMenuForm ||
    !isNewForm &&
    (isLoadingMenu || !isFetchedMenu)
  ) {
    return <FieldsetSkeleton />;
  }

  if (errorMenu) {
    return <Alert theme="danger">Menu not found.</Alert>;
  }

  if (!menuData) {
    return <Alert theme="danger">Menu not found</Alert>;
  }

  type MenuItemsFieldArrayProps = {
    control: Control<MenuMutateSchema>;
    parentName: string;
  };

  const MenuItemsFieldArray: React.FC<MenuItemsFieldArrayProps> = ({
    control,
    parentName,
  }) => {
    // TODO: implement append and remove functions
    const { fields, /*append, remove,*/ move } = useFieldArray({
      control,
      name: parentName as
        | "menuItems"
        | `menuItems.${number}.children`
        | `menuItems.${number}.children.${number}.children`,
    });

    return (
      <ul>
        {fields.map((field, index) => {
          // Use a type assertion here to ensure TypeScript knows `children` can exist
          const typedField = field as FieldArrayWithId<
            MenuItemSchema,
            "children"
          >;

          return (
            <li key={field.id}>
              <MenuItem
                field={typedField}
                index={index}
                parentName={parentName}
                move={move}
                control={control}
              />
              {typedField.children && (
                <div className="pl-6">
                  <MenuItemsFieldArray
                    control={control}
                    parentName={`${parentName}.${index}.children`}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const flattenMenuItems = (
    items: MenuItemSchema[],
    parentId: string | null = null
  ): any[] => {
    return items.flatMap((item) => {
      const { children, ...rest } = item;
      const currentItem = {
        ...rest,
        parentId: parentId ?? undefined,
      };
      const childItems = item.children
        ? flattenMenuItems(item.children, item.id)
        : [];
      return [currentItem, ...childItems];
    });
  };

  const updateOrdering = (
    items: MenuItemSchema[],
    parentId: string | undefined = undefined
  ): MenuItemSchema[] => {
    return items.map((item, index) => {
      const updatedItem = {
        ...item,
        order: index,
        parentId,
        children: item.children ? updateOrdering(item.children, item.id) : [],
      };
      return updatedItem;
    });
  };

  const onSubmit = (data: MenuMutateSchema) => {
    const updatedMenuItems = updateOrdering(data.menuItems ?? []);
    const flattenedMenuItems = flattenMenuItems(updatedMenuItems);
    const payload = {
      id: data.id,
      name: data.name,
      title: data.title,
      menuItems: flattenedMenuItems,
    };
    mutate(payload);
  };

  return (
    <Form {...reactForm}>
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardTitle>{isNewForm ? "Create Form" : "Edit Form"}</CardTitle>
          <fieldset className="mb-4 space-y-4">
            <Legend
              className="mb-4"
              title="Menu Details"
              description="Update role menu details."
            />

            <FormField
              control={control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Menu Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription className="text-xs text-gray-500">
                    {id}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </fieldset>
        </Card>
        <Card>
          <fieldset className="mb-4 space-y-4">
            <Legend
              className="mb-4"
              title="Menu Item(s)"
              description="Update menu items."
            />
          </fieldset>
          <DndProvider backend={HTML5Backend}>
            <nav>
              <MenuItemsFieldArray control={control} parentName="menuItems" />
            </nav>
          </DndProvider>
        </Card>
        <div className="flex justify-between">
          <Button
            type="button"
            variant="default"
            size="sm"
            onClick={() => navigate("./..")}
          >
            Cancel
          </Button>
          <div className="space-x-4">
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

type LegendProps = {
  title: string;
  description: string;
  className?: string;
};
const Legend: React.FC<LegendProps> = ({ title, description, className }) => {
  return (
    <legend className={cn(className)}>
      <h2 className="font-semibold text-base leading-7 text-gray-900">
        {title}
      </h2>
      <p className="mt-0 text-xs leading-6 text-gray-500">{description}</p>
    </legend>
  );
};

export default MenuForm;
