import RoleForm from "components/config/role/form";
import PageHeader from "components/PageHeader";
import { useParams } from "react-router-dom";

const ConfigRoleMutatePage = () => {
  const { id } = useParams();

  return (
    <>
      <PageHeader>
        <div className="text-xl">Create Role</div>
      </PageHeader>
      <div className="space-y-4">
        <RoleForm id={id} />
        {/* <PermissionsButton id={id} /> */}
        {/* <MenuPermissionsButton id={id} /> */}
      </div>
    </>
  );
};

export default ConfigRoleMutatePage;
