import UserForm from "components/config/user/form";
import PageHeader from "components/PageHeader";

const UserCreatePage = () => {
  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Create User</div>
        </div>
      </PageHeader>
      <div className="pt-4">
        <UserForm />
      </div>
    </>
  );
};

export default UserCreatePage;
