import { useState, useCallback, useRef } from "react";

import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * Please note: to use this in development, StrictMode needs to be turned off in index.js
 * @see: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5468
 * @param {Object} options
 * @param {RedirectRequest} options.msalRequest
 * @param {import("./useFormData").onSuccessSchema | undefined} options.onSuccess
 * @param {import("./useFormData").onErrorSchema | undefined} options.onError
 * @returns
 */
const useFetchWithMSAL = ({ msalRequest, onSuccess, onError } = {}) => {
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);

  const requestCounter = useRef(0);

  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      ...msalRequest,
      account: instance.getActiveAccount(),
      redirectUri: "/",
    }
  );

  /**
   * Execute a fetch request with the given options.
   * "request not ready" error will be thrown if the MSAL request is not ready.
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {string} endpoint: The endpoint to call
   * @param {FormData | object} data: The data to send to the endpoint, if any
   * @param {object} variables: Any variables to be returned in the onError callback
   * @returns {Promise<{requestId: number, data: any, error: string}>}
   */
  const execute = async (
    method,
    endpoint,
    data = null,
    variables = undefined
  ) => {
    const currentRequestId = ++requestCounter.current;
    setIsLoading(true);

    if (navigator.onLine && msalError) {
      /**
       * Offline users are allowed to continue making requests which will be
       * handled by the service worker.
       */
      setError(msalError);
      return { requestId: currentRequestId, error: msalError, data: null };
    }

    if (navigator.onLine && !result?.accessToken) {
      /**
       * Wait for request to complete before continuing
       */
      return {
        requestId: currentRequestId,
        error: "request not ready",
        data: null,
      };
    }

    try {
      const headers = new Headers();
      const accessToken = result?.accessToken ?? "";

      const bearer = `Bearer ${accessToken}`;
      if (navigator.onLine && isNullEmptyOrWhitespace(accessToken)) {
        // This can be a difficult error to debug, so we'll throw an error
        // if we don't have a bearer token
        console.error(
          "No bearer token found. Please ensure your authConfig scopes are correct."
        );
        return;
      }

      /**
       * TEMP: don't use for now
       * See: api/services/AuthenticationService.js
       */
      // headers.append("Authorization", bearer);

      // We use authorizationCustom instead of authorization because Azure SWA overrides the Authorization header
      headers.append("authorizationcustom", bearer);

      if (data) {
        // GET requests cannot have a body
        if (!(data instanceof FormData)) {
          // Some POST, PUT, DELETE requests require a JSON body
          // while others require a FormData body
          headers.append("Content-Type", "application/json");
        }
      }

      /* body can be FormData or JSON */
      const body = !!data
        ? data instanceof FormData
          ? data
          : JSON.stringify(data ?? {})
        : null;

      let options = {
        method: method,
        headers: headers,
        body,
      };

      /* Important: endpoint must be lowercase for SWA to work correctly */
      endpoint = endpoint.toLowerCase();

      const response = await fetch(endpoint, options);

      // Handle downloads
      let responseBody;
      if (response.headers.get("content-disposition")) {
        responseBody = await response.blob();
      } else {
        responseBody = await response.json();
      }

      if (!response.ok) {
        const errMessage = responseBody?.d?.error ?? "Error request failed.";
        setError(errMessage);
        onError?.(errMessage, variables);

        return {
          requestId: currentRequestId,
          error: errMessage,
          data: responseBody,
        };
      }

      setData(responseBody);
      onSuccess?.(response, responseBody, variables);

      return { requestId: currentRequestId, data: responseBody };
    } catch (e) {
      /**
       * If the request fails due to a network error, just ignore it.
       */
      if (navigator.onLine) {
        console.error("Error: ", e);
        const errMessage = "Error request failed.";
        setError(errMessage);
        onError?.(errMessage, variables);
      }

      // throw e;
      return {
        requestId: currentRequestId,
        error: "Error request failed.",
        data: null,
      };
    } finally {
      setIsLoading(false);
      setIsFetched(true);
    }
  };

  return {
    isLoading,
    isFetched,
    error,
    data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
  };
};

export default useFetchWithMSAL;
