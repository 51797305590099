import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { authConfig } from "authConfig.js";

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: authConfig.appInsights.instrumentationKey,
    enableAutoRouteTracking: true,
    disableFetchTracking: false, // Track fetch requests (optional)
    disableAjaxTracking: false, // Track AJAX requests (optional)
    enableSessionStorageBuffer: true, // Store telemetry in sessionStorage when offline
    maxBatchInterval: 15000, // Send telemetry every 15 seconds when online
  },
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };
