import {
  MenuItemSchema,
  useActiveMenuItem,
  useMenuGetOneByNameFromCache,
} from "hooks/useMenu";
import { useUser } from "hooks/useUser";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem } from "./core";
import ListSkeleton from "./core/Lists/ListSkeleton";

interface RecentVisitsProps {
  className?: string;
}

type MenuItemWithParent = MenuItemSchema & { parent?: MenuItemSchema };

export default function RecentVisits({ className }: RecentVisitsProps) {
  const activeMenuItem = useActiveMenuItem("main");
  const { data: menu } = useMenuGetOneByNameFromCache({ name: "main" });
  const menuItems = menu?.menuItems;
  const navigate = useNavigate();

  const { user } = useUser();

  const recentVisits = useMemo(() => {
    let result: MenuItemWithParent[] = [];
    if (
      !menuItems ||
      user?.username === undefined ||
      activeMenuItem?.id === undefined
    ) {
      return result;
    }

    function getRecentVisitsFromLocalStorage(userName: string) {
      const recentVisits = localStorage.getItem(`recentVisits-${userName}`);
      return JSON.parse(recentVisits || "[]") as string[];
    }

    const recentVisits = getRecentVisitsFromLocalStorage(user.username);

    // menus to array of ID keys
    const menuIdsLookup = new Map<string, MenuItemWithParent>();
    for (const parentMenu of menuItems) {
      if (!parentMenu.children) {
        continue;
      }
      for (const menuItem of parentMenu.children) {
        menuIdsLookup.set(menuItem.id, { ...menuItem, parent: parentMenu });
      }
    }

    const newRecentVisits = recentVisits.reduce(
      (acc: MenuItemWithParent[], visit: any) => {
        const menu = menuIdsLookup.get(visit);
        if (menu && menu.id !== activeMenuItem.id) {
          acc.push(menu);
        }
        return acc;
      },
      []
    );

    return newRecentVisits;
  }, [menuItems, activeMenuItem?.id, user?.username]);

  function handleOnClickRecentVisit(visit: any) {
    return navigate(visit.href);
  }

  return recentVisits.length > 0 ? (
    <List size="small" theme="striped" className={className}>
      {recentVisits.map((visit: MenuItemWithParent) => (
        <ListItem
          key={visit.id}
          onClick={() => handleOnClickRecentVisit(visit)}
        >
          <span>{visit.parent?.title}</span>{" "}
          <span className="text-gray-300">&nbsp;/&nbsp;</span>{" "}
          <span className="text-primary">{visit.title}</span>
        </ListItem>
      ))}
    </List>
  ) : (
    <ListSkeleton />
  );
}
