import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { parseJSON } from "helpers/stringUtilities";
import { toast } from "react-toastify";
import useFetch from "./useFetch";
import { protectedResources } from "authConfig";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { z } from "zod";
import {
  onErrorRequest,
  onErrorUpdateMutation,
  onSuccessfulMutation,
  onSuccessfulRequest,
} from "types";
import { MenusContext } from "context/MenuProvider";

const menuItemBaseSchema = z.object({
  id: z.string(),
  title: z.string(),
  href: z.string(),
  order: z.number(),
  parentId: z.string().optional(),
  permissionId: z.string().optional(),
  menuId: z.string(),
  meta: z.string().optional(),
});

export const menuItemSchema = menuItemBaseSchema.extend({
  children: z
    .array(
      menuItemBaseSchema.extend({
        children: z.array(menuItemBaseSchema).optional(),
      })
    )
    .optional(),
});

export type MenuItemSchema = z.infer<typeof menuItemSchema> & {
  children?: MenuItemSchema[];
};

export const menuSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  title: z.string(),
  isLegacy: z.boolean(),
  menuItems: z.array(menuItemSchema).optional(),
});

export type Menu = z.infer<typeof menuSchema>;

export const useMenus = () => {
  const context = useContext(MenusContext);

  if (!context) {
    throw new Error("useMenus must be used within a AppDataProvider");
  }

  return context;
};

// TODO: this is currently not used as it relates to a new
// menu system structure that is not yet implemented
export const useMenuGetAllFromCache = ({
  enabled = true,
}: { enabled?: boolean } = {}) => {
  const { isLoading, isFetched, error, data } = useMenus();

  const fetchData = useCallback(async () => {
    return data;
  }, [data]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
  };
};

type useMenuGetAllProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
export const useMenuGetAll = ({ enabled = true }: useMenuGetAllProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error("Error fetching menus", error);
      toast.error(error ?? "Failed to fetch menu data");
    },
  });

  const [data, setData] = React.useState<Menu[] | undefined | null>(undefined);

  const fetchData = useCallback(
    async (variables?: any) => {
      const { data, error } = await execute(
        "GET",
        "/api/menus-get",
        undefined,
        variables
      );

      if (!isNullEmptyOrWhitespace(error)) {
        // error bubbled up to onError
        setData(null);
        return null;
      }

      const menus = data?.d ?? [];
      const result = z.array(menuSchema).safeParse(menus);
      if (!result.success) {
        console.error("Error parsing menu items", result.error);
        setData(null);
        return null;
      }

      setData(result.data);

      return result.data;
    },
    [execute]
  );

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
    refetch: fetchData,
  };
};

type useMenuGetOneByIdFromCacheProps = {
  enabled?: boolean;
  id: string;
};
export const useMenuGetOneByIDFromCache = ({
  enabled = true,
  id,
}: useMenuGetOneByIdFromCacheProps) => {
  const { isLoading, isFetched, error, data: menus } = useMenus();

  const [data, setData] = React.useState<Menu>();

  const fetchData = useCallback(async () => {
    const menu = menus?.find((m) => m.id === id);

    setData(menu);

    return menu;
  }, [id, menus]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
  };
};

type useMenuGetOneByNameFromCacheProps = {
  enabled?: boolean;
  name: string;
};
export const useMenuGetOneByNameFromCache = ({
  enabled = true,
  name,
}: useMenuGetOneByNameFromCacheProps) => {
  const { isLoading, isFetched, error, data: menus } = useMenus();

  return {
    isLoading,
    isFetched,
    error,
    data: menus?.find((m) => m.name === name),
  };
};

type useMenuGetOneByIdProps = {
  enabled?: boolean;
  id: string;
};
export const useMenuGetOneById = ({
  enabled = true,
  id,
}: useMenuGetOneByIdProps) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error("Error fetching menu", error);
      toast.error(error ?? "Failed to fetch menu data");
    },
  });

  const [data, setData] = React.useState<Menu | undefined | null>(undefined);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", `/api/menu-get?id=${id}`);

    const menu = data?.d ?? null;
    const result = menuSchema.safeParse(menu);
    if (!result.success) {
      console.error("Error parsing menu", result.error);
      setData(null);
      return;
    }

    setData(result.data);

    return result.data;
  }, [execute, id]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
    refetch: fetchData,
  };
};

export type ActiveMenuItem = MenuItemSchema & {
  parent?: MenuItemSchema;
  parsedMeta?: Record<string, any>;
};

function mutateMetaKeys(meta: Record<string, any>) {
  if (!meta || typeof meta !== "object") {
    return;
  }
  if (Object.keys(meta).length === 0) {
    return;
  }

  meta.parsedMeta = Object.keys(meta).reduce((acc, key) => {
    acc[key.toLowerCase()] = meta[key];
    return acc;
  }, {} as Record<string, any>);

  return meta;
}

// Function to recursively find the active menu item
const findActiveMenuItem = (
  menuItems: MenuItemSchema[],
  pathname: string
): ActiveMenuItem | undefined => {
  let closestMatch: ActiveMenuItem | undefined;

  for (const item of menuItems) {
    // Match the current path with the item's href
    if (!isNullEmptyOrWhitespace(item.href)) {
      if (item.href === pathname) {
        const matchedItem: ActiveMenuItem = item;
        // parse meta string to object
        if (matchedItem.meta) {
          matchedItem.parsedMeta = parseJSON(matchedItem.meta);
          // convert all meta keys to lowercase
          mutateMetaKeys(matchedItem.parsedMeta);
        }
        return item;
      } else if (pathname.startsWith(item.href)) {
        if (!closestMatch || item.href.length > closestMatch.href.length) {
          // If the current item is a closer match, set it as the closest match
          closestMatch = item;
        }
      }
    }

    // If the item has children, search recursively
    if (item.children && item.children.length > 0) {
      const activeChild = findActiveMenuItem(item.children, pathname);
      if (activeChild) {
        // parse meta string to object
        if (activeChild.meta) {
          activeChild.parsedMeta = parseJSON(activeChild.meta);
          // convert all meta keys to lowercase
          mutateMetaKeys(activeChild.parsedMeta);
        }
        // set the parent
        activeChild.parent = item;

        return activeChild;
      }
    }
  }

  // Return the closest match if no exact match was found
  // parse meta string to object
  if (closestMatch && closestMatch.meta) {
    closestMatch.parsedMeta = parseJSON(closestMatch.meta);
    // convert all meta keys to lowercase
    mutateMetaKeys(closestMatch.parsedMeta);
  }
  return closestMatch;
};

// Hook to get the current active menu item
export const useActiveMenuItem = (menuName: string) => {
  const { data: menu } = useMenuGetOneByNameFromCache({ name: menuName });
  const location = useLocation();
  const pathname = location.pathname;

  const activeMenuItem = useMemo(() => {
    if (!menu || !menu.menuItems) {
      return undefined;
    }

    return findActiveMenuItem(menu.menuItems, pathname);
  }, [menu, pathname]);

  return activeMenuItem;
};

export const menuDeleteSchema = z.object({
  id: z.string(),
});
export type MenuDeleteSchema = z.infer<typeof menuDeleteSchema>;
type useMenuDeleteProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useMenuDelete = ({ onSuccess, onError }: useMenuDeleteProps) => {
  const { isLoading, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (id: string, variables?: any) => {
      const { data, error } = await execute(
        "DELETE",
        "/api/menu-delete",
        {
          id,
        },
        variables
      );

      if (!isNullEmptyOrWhitespace(error)) {
        // error bubbled up to onError
        return null;
      }

      return data ?? null;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

export const menuMutateSchema = z.object({
  id: z.string().uuid().optional(),
  name: z
    .string()
    .min(1, "Name is required.")
    .max(255, "Name is too long.")
    .regex(/^[0-9a-zA-Z-_]+$/, "Name can only contain 0-9, a-z, A-Z, -, _"),
  title: z.string().min(1, "Title is required.").max(255),
  menuItems: z.array(menuItemSchema).optional(),
});
export type MenuMutateSchema = z.infer<typeof menuMutateSchema>;
type useMenuMutateProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useMenuMutate = ({ onSuccess, onError }: useMenuMutateProps) => {
  const { isLoading, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (variables: MenuMutateSchema) => {
      const { data } = await execute("POST", "/api/menu-post", variables);

      return data;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};
