import MenuList from "components/config/role/menu/list";
import PageHeader from "components/PageHeader";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { useParams } from "react-router-dom";

const ConfigRoleMenuPage = () => {
  const { roleId } = useParams();

  if (isNullEmptyOrWhitespace(roleId)) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Role Menus</div>
        </div>
      </PageHeader>
      <div className="pt-4">
        <MenuList />
      </div>
    </>
  );
};

export default ConfigRoleMenuPage;
