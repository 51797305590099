import { useContext, useEffect, useState, useMemo } from "react";
import { AppDataContext } from "context/AppDataProvider";
import { useUser } from "hooks/useUser";
import useQuery from "hooks/useQuery";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import { Alert, Button, Modal } from "components/core";
import DescriptionList from "components/core/Lists/DescriptionList";
import DescriptionListSkeleton from "components/core/Lists/DescriptionListSkeleton";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import {
  getPWAIDfromFormValuesRecord,
  getReadonlyFormValueByFieldType,
} from "helpers/formsUtilities";
import {
  dateToString,
  isDotNetDateString,
  isSQLDateString,
  isUKDateString,
  localDateToSQL,
  localDateToSQLDate,
  sqlDateObjectFromServerTZ,
} from "helpers/dateUtilities";
import { v4 as uuid } from "uuid";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import { ModalContext } from "context/ModalProvider";
import { BadgeSeverity } from "components/forms/BadgeSeverity";
import { deepClone } from "helpers/dataUtilities";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReportPrintPDF, ReportPrintMode } from "./ReportPrintPDF";
import { Link } from "react-router-dom";
import Card, { CardTitle } from "components/Card";
import { ChevronLeftIcon } from "lucide-react";
import { XIcon } from "lucide-react";
import { useConfigGetMany } from "hooks/useConfig";
import { useFarmGetMany } from "hooks/useFarm";
import { useFormGetMany } from "hooks/useForm";
import { useFormDataGetManyByFormType } from "hooks/useFormData";
import { calcGenericFormDataDates } from "helpers/farmUtilities";
import { useActiveMenuItem } from "hooks/useMenu";

const DEFAULT_UNDEFINED = "null";

export default function AuditReportPage() {
  const { data: config } = useConfigGetMany();
  const { moduleFeatureGroup } = useParams();

  const { id, formId } = useParams();
  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const isNcnDisabled = useMemo(() => {
    if (isNullEmptyOrWhitespace(config?.DisabledNCNForms)) return false;

    return config.DisabledNCNForms.toLowerCase()
      .split(",")
      .includes(formId?.toLowerCase());
  }, [config.DisabledNCNForms, formId]);

  const { pageTitle, setPageTitle } = useContext(AppDataContext);
  const { user } = useUser();
  const { data: farms } = useFarmGetMany();
  const { data: forms } = useFormGetMany();

  const { fromDate, toDate } = calcGenericFormDataDates();

  const {
    data: formDatas,
    isLoading: isLoadingFormData,
    isFetched: isFetchedFormData,
    error: errorFormData,
  } = useFormDataGetManyByFormType({
    enabled:
      !isNullEmptyOrWhitespace(formId) &&
      !isNullEmptyOrWhitespace(houseId) &&
      !isNullEmptyOrWhitespace(formId) &&
      !isNullEmptyOrWhitespace(moduleFeatureGroup) &&
      !isNullEmptyOrWhitespace(fromDate) &&
      !isNullEmptyOrWhitespace(toDate),
    farmId: farmId ?? "",
    houseId: parseInt(houseId ?? "-1"),
    formType: moduleFeatureGroup?.toLowerCase() ?? "",
    moduleId: "audit",
    fromDate: localDateToSQLDate(fromDate) ?? "",
    toDate: localDateToSQLDate(toDate) ?? "",
  });

  const auditData = formDatas?.find(
    (fd) =>
      fd.FormName?.toLowerCase() === formId?.toLowerCase() &&
      (fd.ID?.toString() === id || getPWAIDfromFormValuesRecord(fd) === id)
  );
  const ncnData = formDatas?.find(
    (fd) =>
      fd.FormName?.toLowerCase() === `ncn_${formId?.toLowerCase()}` &&
      fd.ParentPWAID === getPWAIDfromFormValuesRecord(auditData)
  );
  const completedDate = auditData?._DateApplies?.normalised;

  const [printMode, setPrintMode] = useState(ReportPrintMode.AuditOnlyTable);
  const [isPrinting, setIsPrinting] = useState(false);
  const [error, setError] = useState(undefined);

  const farm = farms.find((f) =>
    !isNullEmptyOrWhitespace(farmId)
      ? f.FarmCode.toLowerCase() === farmId.toLowerCase()
      : false
  );

  const auditForm = useMemo(() => {
    if (formId === undefined) {
      return undefined;
    }

    const form = forms.find(
      (f) =>
        f.FormName.toLowerCase() === formId.toLowerCase() &&
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName.toLowerCase() === "audit"
    );
    if (form === undefined) return undefined;

    const formClone = deepClone(form);

    // Filter form fields by FarmType
    if (farm?.FarmType) {
      formClone.FormFields = formClone.FormFields?.filter(
        (ff) =>
          isNullEmptyOrWhitespace(ff.FarmType) ||
          ff.FarmType?.split(",").some(
            (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
          )
      );
    }

    // Filter form fields by FarmGroup
    if (farm?.FarmGroup) {
      formClone.FormFields = formClone.FormFields?.filter(
        (ff) =>
          isNullEmptyOrWhitespace(ff.FarmGroup) ||
          ff.FarmGroup?.split(",").some(
            (fg) => fg.toLowerCase() === farm.FarmGroup.toLowerCase()
          )
      );
    }

    formClone.FormFields.sort((a, b) => {
      // Sort by position
      const positionDiff = a.Position - b.Position;
      if (positionDiff) {
        return positionDiff;
      }

      // Name
      return a.Name < b.Name ? -1 : 1;
    });

    return formClone;
  }, [farm?.FarmType, farm?.FarmGroup, formId, moduleFeatureGroup, forms]);

  const nonConformanceForm = useMemo(() => {
    if (formId === undefined || !forms?.length) {
      return undefined;
    }

    const form = forms.find(
      (f) =>
        f.FormName.toLowerCase() === `ncn_${formId.toLowerCase()}` &&
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName.toLowerCase() === "audit"
    );

    if (form === undefined) {
      setError(
        "No non-conformance form found, please contact your system administrator."
      );

      return undefined;
    }

    const formClone = deepClone(form);

    // Filter form fields by FarmType
    if (farm?.FarmType) {
      formClone.FormFields = formClone.FormFields?.filter(
        (ff) =>
          isNullEmptyOrWhitespace(ff.FarmType) ||
          ff.FarmType?.split(",").some(
            (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
          )
      );
    }

    formClone.FormFields.sort((a, b) => {
      // Sort by position
      const positionDiff = a.Position - b.Position;
      if (positionDiff) {
        return positionDiff;
      }

      // Name
      return a.Name < b.Name ? -1 : 1;
    });

    return formClone;
  }, [farm?.FarmType, formId, moduleFeatureGroup, forms]);

  const handlePrint = (printMode) => {
    setPrintMode(printMode);
    setIsPrinting(true);
  };

  //#region Callbacks

  const buildChildOutput = (child, _primaryQuestionImages, datasource) => {
    const _readonlyValue = getReadonlyFormValueByFieldType(
      child.field,
      child.data
    );
    const _fieldType = child.field.FieldType?.toLowerCase();
    const isHidden =
      typeof child.field.Display === "string" &&
      child.field.Display.toLowerCase() === "h"
        ? true
        : false;

    if (isHidden) {
      return {
        id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
        title: child.field.Name,
        value: _readonlyValue,
        type: "hidden",
        datasource,
      };
    }

    if (_fieldType === "up") {
      // Add image to print array
      for (const imgUrl of _readonlyValue) {
        _primaryQuestionImages.push({
          id: uuid(),
          url: imgUrl,
        });
      }

      return {
        id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
        title: child.field.Name,
        value: _readonlyValue,
        type: "images",
        datasource,
      };
    }

    return {
      id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
      title: child.field.Name,
      value: _readonlyValue,
      type: "text",
      datasource,
    };
  };

  const generateNonConformantTableData = useMemo(() => {
    if (auditForm?.FormFields === undefined) {
      return {
        questionStatusLookup: [],
        reportData: [],
        summaryData: [],
        reportSummaryData: [],
      };
    }
    const reportData = [];
    const summaryData = [];
    const _printImageData = [];

    // Build primary question lookup(s)
    const _primaryQuestionFieldLookup = [];
    const _childQuestionFieldLookup = [];
    const _nonconformanceFieldLookup = [];

    // Audit form fields
    for (const field of auditForm.FormFields) {
      if (
        !isNullEmptyOrWhitespace(field.QuestionGroup) &&
        field.QuestionGroup === field.Ref
      ) {
        _primaryQuestionFieldLookup[field.Ref.toLowerCase()] = field;
      } else {
        const _refKey = field.Ref.toLowerCase();
        const _questionGroupKey =
          field.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

        if (!_childQuestionFieldLookup[_questionGroupKey]) {
          _childQuestionFieldLookup[_questionGroupKey] = [];
        }
        _childQuestionFieldLookup[_questionGroupKey][_refKey] = field;
      }
    }

    // Non-conformance form fields
    const _nonConformanceFormFields = nonConformanceForm?.FormFields ?? [];
    for (const field of _nonConformanceFormFields) {
      const _refKey = field.Ref.toLowerCase();
      for (const primaryQuestionRef of Object.keys(
        _primaryQuestionFieldLookup
      )) {
        const _questionGroupKey = primaryQuestionRef;

        if (!_nonconformanceFieldLookup[_questionGroupKey]) {
          _nonconformanceFieldLookup[_questionGroupKey] = [];
        }
        _nonconformanceFieldLookup[_questionGroupKey][_refKey] = field;
      }
    }

    //Build set of non-conformant primary question refs
    const _nonConformancePrimaryQuestionLookup = new Map(); //Non-conformant primary question field and data for easy lookup
    const _childQuestionLookup = []; //Child field and data for easy lookup
    const _nonconformanceQuestionLookup = []; //Non-Conformance field and data for easy lookup
    // const _childQuestionLookup = new Map(); //Child field and data for easy lookup
    const questionStatusLookup = new Map();

    const auditDataPenValues = auditData?.PenValues ?? [];
    for (const pen of auditDataPenValues) {
      for (const pv of pen.Values) {
        const _primaryQuestionField =
          _primaryQuestionFieldLookup[pv.Ref?.toLowerCase()];

        if (_primaryQuestionField) {
          //Primary question
          const listOption = _primaryQuestionField.ListOptions?.find(
            (lo) => lo.Value === pv.Value
          );

          //Build list of value:counter array
          if (listOption !== undefined) {
            const _existingEntry =
              questionStatusLookup.get(listOption.Text) ?? 0;

            questionStatusLookup.set(listOption.Text, {
              id: _existingEntry.id ?? uuid(),
              text: _existingEntry.text ?? listOption.Text,
              severity: _existingEntry.severity ?? listOption.SeverityColour,
              value: (_existingEntry.value ?? 0) + 1,
            });
          }

          _nonConformancePrimaryQuestionLookup.set(pv.Ref.toLowerCase(), {
            field: _primaryQuestionField,
            data: pv,
          });
        } else {
          //Child

          const _refKey = pv.Ref?.toLowerCase();
          const _questionGroupKey =
            pv.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

          const _childQuestionField =
            _childQuestionFieldLookup[_questionGroupKey]?.[_refKey];

          if (!_childQuestionField) continue;

          if (!_childQuestionLookup[_questionGroupKey]) {
            _childQuestionLookup[_questionGroupKey] = [];
          }

          // Format values such as Dates and Numbers
          // Convert dates
          if (
            isSQLDateString(pv.Value) ||
            isDotNetDateString(pv.Value) ||
            isUKDateString(pv.Value)
          ) {
            const convertedValue = sqlDateObjectFromServerTZ(pv.Value);
            pv.Value = localDateToSQL(convertedValue.normalised, {
              includeOffset: false,
            });
          }

          _childQuestionLookup[_questionGroupKey].push({
            field: _childQuestionField,
            data: pv,
          });
        }
      }
    }

    // Non-conformance questions
    const nonConformanceDataPenValues = ncnData?.PenValues ?? [];
    for (const pen of nonConformanceDataPenValues) {
      for (const pv of pen.Values) {
        const _refKey = pv.Ref?.toLowerCase();
        const _questionGroupKey =
          pv.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

        const _nonconformanceQuestionField =
          _nonconformanceFieldLookup[_questionGroupKey]?.[_refKey];

        if (!_nonconformanceQuestionField) continue;

        if (!_nonconformanceQuestionLookup[_questionGroupKey]) {
          _nonconformanceQuestionLookup[_questionGroupKey] = [];
        }

        // Format values such as Dates and Numbers
        // Convert dates
        if (
          isSQLDateString(pv.Value) ||
          isDotNetDateString(pv.Value) ||
          isUKDateString(pv.Value)
        ) {
          const convertedValue = sqlDateObjectFromServerTZ(pv.Value);
          pv.Value = localDateToSQL(convertedValue.normalised, {
            includeOffset: false,
          });
        }

        _nonconformanceQuestionLookup[_questionGroupKey].push({
          field: _nonconformanceQuestionField,
          data: pv,
        });
      }
    }

    // sort primary questions
    const _sortedPrimaryQuestionRefs = Array.from(
      _nonConformancePrimaryQuestionLookup.values()
    ).sort((a, b) => {
      // Sort by position
      const positionDiff = a.field.Position - b.field.Position;
      if (positionDiff) {
        return positionDiff;
      }

      // Name
      return a.field.Name < b.field.Name ? -1 : 1;
    });

    // Build report data
    for (const primaryQuestion of _sortedPrimaryQuestionRefs) {
      const _questionGroupKey =
        primaryQuestion.field.Ref?.toLowerCase() ?? DEFAULT_UNDEFINED;

      const isNcnPrimaryQuestion =
        (!isNcnDisabled &&
          primaryQuestion.field.ListOptions?.some((option) =>
            option.hasOwnProperty("IsNcn")
          )) ??
        false;

      const hasCompletedNonConformance =
        _nonconformanceQuestionLookup[_questionGroupKey]?.length > 0;

      const _primaryQuestionImages = [];

      const listOptions = primaryQuestion.field.ListOptions;
      const selectedListOption = listOptions?.find(
        (lo) => lo.Value === primaryQuestion.data.Value
      );
      const hasNonConformance =
        !isNcnDisabled &&
        isNcnPrimaryQuestion &&
        selectedListOption !== undefined
          ? selectedListOption.IsNcn?.toString().toLowerCase() === "true"
          : false;
      const value = !isNullEmptyOrWhitespace(selectedListOption)
        ? selectedListOption.Text
        : primaryQuestion.data.Value;
      const statusColor = selectedListOption?.SeverityColour;

      const sectionNormalised = primaryQuestion.field.Section?.toLowerCase();

      if (sectionNormalised === "summary") {
        const newParentRecord = buildChildOutput(
          primaryQuestion,
          _primaryQuestionImages,
          "auditSummary"
        );
        if (!isNullEmptyOrWhitespace(newParentRecord.value)) {
          newParentRecord.type = !isNullEmptyOrWhitespace(listOptions)
            ? "status"
            : "text";
          newParentRecord.statusColor = statusColor;
          summaryData.push(newParentRecord);
        }

        for (const child of _childQuestionLookup[_questionGroupKey] ?? []) {
          const newChildRecord = buildChildOutput(
            child,
            _primaryQuestionImages,
            "auditSummary"
          );
          if (!isNullEmptyOrWhitespace(newChildRecord.value)) {
            summaryData.push(newChildRecord);
          }
        }
      } else {
        const newRecord = {
          id: `${primaryQuestion.field.Ref}`,
          title: primaryQuestion.field.Name,
          children: [
            {
              id: "answer",
              title: "Answer",
              value: value,
              statusColor,
              type: !isNullEmptyOrWhitespace(listOptions) ? "status" : "text",
              datasource: "audit",
            },
            sectionNormalised && {
              id: "section",
              title: "Section",
              value: primaryQuestion.field.Section,
              type: "text",
              datasource: "audit",
            },
            ...(_childQuestionLookup[_questionGroupKey] ?? []).map((child) => {
              return buildChildOutput(child, _primaryQuestionImages, "audit");
            }),
            ...(_nonconformanceQuestionLookup[_questionGroupKey] ?? []).map(
              (child) => {
                return buildChildOutput(
                  child,
                  _primaryQuestionImages,
                  "nonconformance"
                );
              }
            ),
            hasNonConformance &&
              !hasCompletedNonConformance && {
                id: "alert",
                title: "Non-conformances",
                value: (
                  <Alert theme="warning">
                    Non-conformance form has NOT been completed.
                  </Alert>
                ),
                type: "alert",
                datasource: "audit",
              },
          ],
          section: sectionNormalised,
          hasNonConformance,
        };
        reportData.push(newRecord);
      }

      _primaryQuestionImages?.length > 0 &&
        _printImageData.push({
          id: `${primaryQuestion.field.Ref}`,
          title: primaryQuestion.field.Name,
          value: _primaryQuestionImages,
          type: "images",
          datasource: "audit",
          hasNonConformance,
        });
    }

    // Add summary data where primary question is "null"
    const childrenOfNullQuestion =
      _childQuestionLookup[DEFAULT_UNDEFINED] ?? [];
    const excludedChildren = [
      "auditor",
      "dateapplies",
      "pwaid",
      "site",
      "status",
    ];
    for (const unparentedChild of childrenOfNullQuestion.filter(
      (c) => !excludedChildren.includes(c.field.Ref.toLowerCase())
    )) {
      const newRecord = buildChildOutput(unparentedChild, [], "auditSummary");

      summaryData.push(newRecord);
    }

    return {
      questionStatusLookup: Array.from(questionStatusLookup.values()).sort(
        (a, b) => b.severity - a.severity
      ),
      reportData,
      summaryData,
      printImages: _printImageData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auditData?.PenValues,
    auditForm?.FormFields,
    formId,
    id,
    ncnData?.PenValues,
    nonConformanceForm?.FormFields,
  ]);

  //#endregion

  //#region side-effects

  /**
   * Set page title
   */
  useEffect(() => {
    if (auditForm?.FormTitle === undefined) {
      setPageTitle("Report");
      return;
    }

    setPageTitle(`${auditForm.FormTitle} - Report`);
  }, [auditForm?.FormTitle, setPageTitle]);

  //#endregion

  if (isLoadingFormData || !isFetchedFormData) {
    return (
      <main className="flex flex-col flex-grow">
        <div className="relative z-20 bg-white border-b border-gray-100">
          <Breadcrumb
            key="breadcrumb"
            className="print:hidden"
            showHome={false}
            farmRequired={true}
            houseRequired={true}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 p-4">
          <div className="col-span-full">
            <DescriptionListSkeleton />
          </div>
        </div>
      </main>
    );
  }

  if (errorFormData || error) {
    return (
      <main className="flex flex-col flex-grow">
        <div className="relative z-20 bg-white border-b border-gray-100">
          <Breadcrumb
            key="breadcrumb"
            className="print:hidden"
            showHome={false}
            farmRequired={true}
            houseRequired={true}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 p-4">
          <div className="col-span-full flex flex-col space-y-4">
            <Alert theme="danger">{errorFormData?.message ?? error}</Alert>
            <Link to={-1} className="text-primary">
              <ChevronLeftIcon className="inline-block w-4 h-4 mr-2" />
              Back to previous page
            </Link>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="flex flex-col flex-grow">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          key="breadcrumb"
          className="print:hidden"
          showHome={false}
          farmRequired={true}
          houseRequired={true}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="mb-2 flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              {pageTitle}
            </div>
            {generateNonConformantTableData.reportData.length > 0 && (
              <>
                <Button
                  // onClick={handlePrint}
                  theme="primary"
                  options={[
                    {
                      id: "print-audit-table",
                      text: "Print Audit",
                      onClick: () =>
                        handlePrint(ReportPrintMode.AuditOnlyTable),
                    },
                    {
                      id: "print-nonconformances-table",
                      text: "Print Non-Conformances",
                      onClick: () =>
                        handlePrint(ReportPrintMode.NonConformancesOnlyTable),
                    },
                    {
                      id: "print-audit-table-w-images",
                      text: "Print Audit (w/ images)",
                      onClick: () =>
                        handlePrint(
                          ReportPrintMode.AuditOnlyTableWithLargeImages
                        ),
                    },
                    {
                      id: "print-nonconformances-table-w-images",
                      text: "Print Non-Conformances (w/ images)",
                      onClick: () =>
                        handlePrint(
                          ReportPrintMode.NonConformancesOnlyTableWithLargeImages
                        ),
                    },
                  ]}
                >
                  Print
                </Button>
                {isPrinting && (
                  <Modal
                    title={
                      <div className="mb-4">Downloading Audit Report...</div>
                    }
                    open={true}
                    showCancel={false}
                    className="max-w-3xl"
                  >
                    <div className="flex justify-center">
                      <XIcon
                        className="w-6 h-6 text-gray-500 cursor-pointer absolute top-2 right-2"
                        onClick={() => setIsPrinting(false)}
                      />
                      <PDFDownloadLink
                        document={
                          <ReportPrintPDF
                            farm={farm}
                            auditForm={auditForm}
                            completedDate={completedDate}
                            auditor={auditData?.CreatedBy ?? user?.fullname}
                            nonconformanceCounts={
                              generateNonConformantTableData.questionStatusLookup
                            }
                            reportData={
                              generateNonConformantTableData.reportData
                            }
                            summaryData={
                              generateNonConformantTableData.summaryData
                            }
                            printImages={
                              generateNonConformantTableData.printImages
                            }
                            printMode={printMode}
                            configMediaUrl={config.mediaUrl}
                          />
                        }
                        fileName={`auditreport_${farm.FarmCode}_${auditForm.FormName}.pdf`}
                        className="inline-flex items-center justify-start py-2 px-4 shadow-sm uppercase bg-primary bg-gradient-to-r from-primary to-secondary border-primary hover:bg-primary-dark text-white focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10 focus:border-primary focus:text-primary-lighter rounded-sm"
                      >
                        {({ blob, url, loading, error }) =>
                          loading
                            ? "Loading document..."
                            : "Download Audit Report PDF"
                        }
                      </PDFDownloadLink>
                    </div>
                  </Modal>
                )}
              </>
            )}
          </div>
          <ReportDetail
            farm={farm}
            form={auditForm}
            ncnForm={nonConformanceForm}
            auditor={auditData?.CreatedBy ?? user?.fullname}
            completedDate={completedDate}
            data={generateNonConformantTableData.reportData}
            summaryData={generateNonConformantTableData.summaryData}
            pwaId={getPWAIDfromFormValuesRecord(auditData)}
            questionStatusLookup={
              generateNonConformantTableData.questionStatusLookup
            }
            auditStatus={auditData?.AuditStatus}
          />
        </div>
      </div>
    </main>
  );
}

const ReportDetail = ({
  farm,
  form,
  ncnForm,
  auditor,
  completedDate,
  data,
  summaryData,
  pwaId,
  questionStatusLookup,
}) => {
  const location = useLocation();
  const activeMenuItem = useActiveMenuItem("main");
  const { id } = useParams();

  const { setShowModal } = useContext(ModalContext);

  const onClickImage = (url) => {
    setShowModal(true, <img src={url} alt={url} />);
  };

  return (
    <div className="flex flex-col flex-grow w-full">
      <div className="grid tablet:grid-cols-12 gap-4">
        <div className="col-span-8 flex">
          <Card className="flex-grow">
            <CardTitle>Details</CardTitle>
            {farm?.FarmName !== undefined &&
            form?.FormName !== undefined &&
            completedDate !== undefined ? (
              <DescriptionList
                className="divide-y divide-gray-100 -mx-4"
                items={[
                  {
                    id: "farmcode",
                    title: "Farm",
                    value: `${farm?.FarmName} (${farm?.FarmCode})`,
                  },
                  { id: "form", title: "Form", value: form?.FormName },
                  {
                    id: "date",
                    title: "Date",
                    value: dateToString(completedDate, {
                      includeTime: false,
                    }),
                  },
                  {
                    id: "auditor",
                    title: "Auditor",
                    value: auditor,
                  },
                  // {
                  //   id: "flockdate",
                  //   title: "Flock Date",
                  //   value: dateToString(placement?._CropDate?.normalised, {
                  //     includeTime: false,
                  //   }),
                  // },
                  // {
                  //   id: "auditStatus",
                  //   title: "Audit Status",
                  //   value: auditStatusToText(auditStatus),
                  // },
                ]}
              />
            ) : (
              <DescriptionListSkeleton />
            )}
          </Card>
        </div>
        <div className="col-span-4 flex">
          <Card className="flex-grow">
            <CardTitle>Summary</CardTitle>
            {questionStatusLookup?.length > 0 ? (
              <DescriptionList
                className="divide-y divide-gray-100 -mx-4"
                items={questionStatusLookup.map((status) => ({
                  id: status.id,
                  title: status.text,
                  value: (
                    <BadgeSeverity severity={status.severity}>
                      {status.value}
                    </BadgeSeverity>
                  ),
                }))}
              />
            ) : (
              <DescriptionListSkeleton />
            )}
          </Card>
        </div>

        <div className="col-span-full">
          {summaryData.length > 0 &&
            (form?.FormTitle !== undefined ? (
              <Card>
                <CardTitle>{form?.FormTitle ?? "Form"} Summary</CardTitle>
                <DescriptionList
                  className="divide-y divide-gray-100 page-break -mx-4"
                  items={summaryData}
                />
              </Card>
            ) : (
              <Card>
                <DescriptionListSkeleton />
              </Card>
            ))}
        </div>

        <div className="col-span-full">
          {data?.length > 0 ? (
            <div>
              <h3 className="text-lg text-medium text-gray-600 mb-2">
                Questions
              </h3>
              <div className="space-y-4">
                {data.map((row) => (
                  <Card key={row.id}>
                    <CardTitle>
                      {location !== undefined &&
                      ncnForm?.FormName !== undefined &&
                      pwaId !== undefined &&
                      form?.FormName !== undefined &&
                      id !== undefined ? (
                        <Link
                          to={buildFormPageUrl(
                            activeMenuItem,
                            ncnForm?.FormName,
                            pwaId,
                            form?.FormName,
                            id
                          )}
                        >
                          {row.title}
                        </Link>
                      ) : (
                        <div className="animate-pulse text-sm font-medium text-gray-500 title">
                          <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </CardTitle>
                    <DescriptionList
                      key={`${row.id}-list`}
                      className="divide-y divide-gray-100 -mx-4"
                      items={row.children}
                      onClickImage={(url) => onClickImage(url)}
                    />
                  </Card>
                ))}
              </div>
            </div>
          ) : (
            <DescriptionListSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};
