import Card from "components/Card";
import { tryInvalidateApiCache } from "helpers/common";
import { useMenuDelete, useMenuGetAll } from "hooks/useMenu";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function MenuList() {
  const {
    data: menusData,
    isLoading: isLoadingMenus,
    refetch: refetchMenus,
  } = useMenuGetAll();

  const menus = React.useMemo(
    () => menusData?.filter((menu) => !menu.isLegacy),
    [menusData]
  );

  const { mutate: deleteMenuMutate } = useMenuDelete({
    onSuccess: () => {
      toast.success("Menu deleted successfully.");

      tryInvalidateApiCache("/api/menus-get");
      refetchMenus();
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  if (isLoadingMenus) return <div>Loading menus...</div>;

  const handleClickDeleteMenu = (id: string) => {
    if (window.confirm("Are you sure you want to delete this menu?")) {
      deleteMenuMutate(id);
    }
  };

  return (
    <Card>
      <ul className="divide-y divide-gray-100">
        {menus &&
          menus.map((menu) => (
            <li key={menu.id}>
              <div className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 tablet:w-auto tablet:max-w-none tablet:pl-0">
                <div>
                  <Link
                    to={`${menu.id}`}
                    className="text-sm font-medium hover:text-primary-dark"
                  >
                    {menu.title}
                  </Link>
                </div>
                <div className="text-xs inline-flex space-x-2 divide-x">
                  <Link
                    to={`${menu.id}`}
                    className="text-primary hover:text-primary-dark"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => handleClickDeleteMenu(menu.id)}
                    className="text-primary hover:text-primary-dark pl-2"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </Card>
  );
}
