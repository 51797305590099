import Card, { CardSkeleton } from "components/Card";
import { useMenuGetAll } from "hooks/useMenu";
import { Link } from "react-router-dom";

type MenuListProps = {
  className?: string;
};
export default function MenuList({ className }: MenuListProps) {
  const { isLoading, error, data: menus } = useMenuGetAll();

  if (isLoading) {
    return <CardSkeleton />;
  }

  if (error) {
    return <Card>{error}</Card>;
  }

  // const handleClickDeleteMenu = (id: string) => {
  //   // deleteMenuMutate(id);
  // };

  return (
    <Card className={className}>
      <div className="">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="font-semibold py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 tablet:pl-0"
              >
                Menu
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {menus &&
              menus.map((menu) => (
                <tr key={menu.id}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 tablet:w-auto tablet:max-w-none tablet:pl-0">
                    <div>
                      <Link to={`${menu.id}`}>{menu.title}</Link>
                    </div>
                    <div className="text-xs inline-flex space-x-2 divide-x">
                      <Link
                        to={`${menu.id}`}
                        className="text-primary hover:text-primary-dark"
                      >
                        Edit permissions
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
