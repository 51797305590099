import Card from "components/Card";
import { Alert, Button } from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import {
  userCreateSchema,
  userUpdateSchema,
  useUserDelete,
  useUserForm,
  useUserGetAssignedFarms,
  useUserGetOneById,
  useUserMutate,
} from "hooks/useUser";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import React from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Legend from "../legend";
import FarmSelect from "./farm-select";
import { useRoleGetAll, useRoleGetOne } from "hooks/useRole";
import { isNullEmptyOrWhitespace, tryInvalidateApiCache } from "helpers/common";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "components/placement/Input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import { Link } from "react-router-dom";

const UserForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isNewForm = isNullEmptyOrWhitespace(id);

  const {
    data: userForm,
    isLoading: isLoadingUserForm,
    isFetched: isFetchedUserForm,
    error: errorUserForm, // TODO: display error to user
  } = useUserForm();

  const {
    data: user,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useUserGetOneById({
    enabled: !isNewForm && !isNullEmptyOrWhitespace(id),
    id: id ?? "",
  });

  const {
    isLoading: isLoadingAssignedFarms,
    error: errorAssignedFarms,
    data: assignedFarms,
  } = useUserGetAssignedFarms({
    enabled: !isNewForm && !isNullEmptyOrWhitespace(id),
    id: id ?? "",
  });

  const {
    data: roles,
    isLoading: isLoadingRoles,
    isFetched: isFetchedRoles,
    error: errorRoles,
  } = useRoleGetAll({
    enabled: true,
  });

  const userMutateSchema = isNewForm ? userCreateSchema : userUpdateSchema;
  type UserMutateSchema = z.infer<typeof userMutateSchema>;
  const reactForm = useForm<UserMutateSchema>({
    defaultValues: isNewForm
      ? {
          accountEnabled: true,
          displayName: "",
          emailAddress: "",
          username: "",
          forceChangePasswordNextSignIn: false,
          roleId: "",
          permissionLevelId: "",
          assignedFarms: [],
          password: "",
          confirmPassword: "",
        }
      : {
          id: id,
          displayName: user?.fullname ?? "",
          emailAddress: user?.email ?? "",
          roleId: user?.roleId ?? "",
          permissionLevelId: user?.permissionLevelId?.toString(),
          assignedFarms: assignedFarms?.map((f) => f.farmcode) ?? [],
          password: "",
          confirmPassword: "",
        },
    resolver: zodResolver(userMutateSchema),
  });

  const selectedRoleId = reactForm.watch("roleId") as string;
  const {
    data: userRole,
    // isLoading: isLoadingUserRole,
    error: errorUserRole,
  } = useRoleGetOne({
    enabled: !isNullEmptyOrWhitespace(selectedRoleId),
    id: selectedRoleId ?? "",
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error loading role data.");
    },
  });

  const { mutate: mutateUser } = useUserMutate({
    onSuccess: async (response, responseBody) => {
      const message =
        responseBody?.message ?? isNewForm
          ? "User created successfully."
          : "User updated successfully.";
      toast.success(message);

      await tryInvalidateApiCache("/api/users-get");

      navigate("./..");
    },
    onError: (errMessage) => {
      toast.error(
        isNewForm
          ? `Error creating user: ${errMessage}`
          : `Error updating user: ${errMessage}`
      );
    },
  });

  const { mutate: deleteMutate } = useUserDelete({
    onSuccess: async (response, responseBody) => {
      const message = responseBody?.message ?? "User deleted successfully.";
      toast.success(message);

      await tryInvalidateApiCache("/api/users-get");

      navigate("./..");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error deleted user");
    },
  });

  const resetForm = reactForm.reset;
  React.useEffect(() => {
    if (user && assignedFarms) {
      const newFormData = {
        id: user.id,
        displayName: user.fullname,
        emailAddress: user.email,
        username: user.username,
        roleId: user.roleId,
        permissionLevelId: user.permissionLevelId?.toString(),
        assignedFarms: assignedFarms.map((f) => f.farmcode),
        password: "",
        confirmPassword: "",
      };
      resetForm(newFormData);
    }
  }, [user, assignedFarms, resetForm]);

  if (
    isLoadingUserForm ||
    !isFetchedUserForm ||
    isLoadingRoles ||
    // isLoadingUserRole ||
    (!isNewForm && isLoadingUser) ||
    (!isNewForm && isLoadingAssignedFarms) ||
    (!isNewForm && !isFetchedRoles)
  ) {
    return (
      <Card>
        <FieldsetSkeleton />
      </Card>
    );
  }

  if (errorUser || errorAssignedFarms || errorRoles || errorUserRole) {
    return <Alert theme="danger">Error loading user data.</Alert>;
  }

  if (!isNewForm && !user) {
    return (
      <Alert theme="danger">
        User not found. <Link to="./..">Return to list</Link>.
      </Alert>
    );
  }

  if (!userForm) {
    return <Alert theme="danger">User form not found.</Alert>;
  }

  if (errorUserForm) {
    return <Alert theme="danger">Error loading user form.</Alert>;
  }

  const onSubmit = async (data: UserMutateSchema) => {
    await mutateUser(data);
  };

  return (
    <Form {...reactForm}>
      <form
        className="space-y-4"
        onSubmit={reactForm.handleSubmit(onSubmit)}
        noValidate
      >
        <Card>
          <fieldset className="mb-4 space-y-4">
            <Legend
              className="mb-4"
              title="User Information"
              description="Enter user details."
            />
            <FormField
              control={reactForm.control}
              name="displayName"
              render={({ field }) => (
                <FormItem variant="overlap">
                  <FormLabel variant="overlap" required>
                    Display Name
                  </FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="E.g. John Doe" />
                  </FormControl>
                  <FormDescription className="text-xs text-gray-500">
                    {id}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={reactForm.control}
              name="emailAddress"
              render={({ field }) => (
                <FormItem variant="overlap">
                  <FormLabel variant="overlap" required>
                    Email Address
                  </FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="E.g. user@company.com" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/**
             * The inclusion of username here makes no sense
             * because we use the email address but is needed for legacy reasons.
             * It is also disabled for editing because it cannot be changed without creating a duplicate
             * user in Legacy DB.
             */}
            <FormField
              control={reactForm.control}
              name="username"
              render={({ field }) => (
                <FormItem variant="overlap">
                  <FormLabel variant="overlap" required>
                    User name
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="E.g. joebloggs"
                      disabled={!isNewForm}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={reactForm.control}
              name="password"
              render={({ field }) => (
                <FormItem variant="overlap">
                  <FormLabel variant="overlap" required={isNewForm}>
                    Password
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                      placeholder="********"
                      disabled={!isNewForm}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={reactForm.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem variant="overlap">
                  <FormLabel variant="overlap" required={isNewForm}>
                    Confirm Password
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                      placeholder="********"
                      disabled={!isNewForm}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </fieldset>
        </Card>
        <Card>
          <fieldset className="mb-4 space-y-4">
            <Legend
              className="mb-4"
              title="Role Information"
              description="Enter role details."
            />
            <FormField
              control={reactForm.control}
              name="permissionLevelId"
              render={({ field }) => (
                <FormItem variant="overlap">
                  <FormLabel variant="overlap">Permission Level ID</FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="-- Select --" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {userForm.legacyPermLevelOptions?.map((option) => (
                          <SelectItem
                            key={option.value}
                            value={option.value.toString()}
                          >
                            {option.text}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={reactForm.control}
              name="roleId"
              render={({ field }) => (
                <FormItem variant="overlap">
                  <FormLabel variant="overlap">Role</FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="-- Select --" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {roles?.map((r) => (
                          <SelectItem key={r.id} value={r.id}>
                            {r.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </fieldset>
        </Card>
        <Card>
          <fieldset className="mb-4 space-y-4">
            <Legend
              className="mb-4"
              title="Farms"
              description="Select the farms this user has access to."
            />
            {/* // TODO: include farmSelection  */}
            {isNullEmptyOrWhitespace(userRole?.farmAccessType) && (
              <Alert theme="warning">
                This user cannot access any farms. Individual farms cannot be
                selected.
              </Alert>
            )}
            {userRole?.farmAccessType === "Assigned Farms" && (
              <FarmSelect reactForm={reactForm} />
            )}
            {userRole?.farmAccessType === "All Farms Groups" && (
              <Alert theme="success">
                This user has access to All Farms. Individual farms cannot be
                selected.
              </Alert>
            )}
          </fieldset>
        </Card>
        <div className="flex flex-col-reverse tablet:flex-row tablet:justify-between tablet:space-x-2 tablet:space-y-0">
          <div className="space-x-2">
            <Button
              className="w-full tablet:w-auto"
              type="button"
              onClick={() => void navigate("./..")}
            >
              Cancel
            </Button>
            {!isNewForm && (
              <Button
                type="button"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you want to delete this user?")
                  ) {
                    void deleteMutate(id!);
                  }
                }}
              >
                Delete
              </Button>
            )}
          </div>
          <Button
            className="mb-2 w-full tablet:mb-0 tablet:w-auto"
            theme="primary"
            type="submit"
          >
            {!isNewForm && user ? "Update user" : "Create user"}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default UserForm;
