import RoleList from "components/config/role/list";
import PageHeader from "components/PageHeader";
import Button from "components/placement/Button";
import { PlusCircleIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ConfigRolePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Roles &amp; Permissions</div>
          <Button
            type="button"
            variant="primary"
            onClick={() => navigate("new")}
          >
            <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
          </Button>
        </div>
      </PageHeader>
      <div className="pt-4">
        <RoleList />
      </div>
    </>
  );
};

export default ConfigRolePage;
