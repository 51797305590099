import { useMemo } from "react";
import { useState } from "react";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { getDashboardById } from "helpers/dashboardUtilities";
import SectionTitle from "components/SectionTitle";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import Tabs from "components/Tabs";
import Dashboard from "components/Dashboard";
import { useDashboardGetByMenuId } from "hooks/useDashboard";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { PersistantFormValuesProvider } from "context/PersistantFormValuesProvider";
import { useActiveMenuItem } from "hooks/useMenu";

export default function DashboardsListPage() {
  const activeMenuItem = useActiveMenuItem("main");
  const { dashboards } = useDashboardGetByMenuId({
    enabled: !isNullEmptyOrWhitespace(activeMenuItem?.id),
    id: activeMenuItem?.id,
  });

  const [selectedDashboardId, setSelectedDashboardId] = useState(undefined);

  const selectedDashboard = useMemo(() => {
    if (!dashboards?.length) {
      return undefined;
    }

    return getDashboardById(
      dashboards,
      selectedDashboardId ?? dashboards?.[0]?.id
    );
  }, [dashboards, selectedDashboardId]);

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>
            {!isNullEmptyOrWhitespace(activeMenuItem?.title) ? (
              activeMenuItem.title
            ) : (
              <div className="w-1/3">
                <FieldSkeleton />
              </div>
            )}
          </SectionTitle>
        </div>

        <div className="col-span-full">
          <div className="flex flex-grow flex-col">
            {dashboards?.length > 0 ? (
              <div>
                {dashboards?.length > 1 && (
                  <Tabs
                    className="mb-4"
                    tabs={dashboards.map((d) => ({
                      name: d.title,
                      id: d.id,
                      current:
                        selectedDashboardId !== undefined
                          ? selectedDashboardId === d.id
                          : dashboards?.[0]?.id === d.id,
                    }))}
                    onChange={(id) => setSelectedDashboardId(id)}
                  ></Tabs>
                )}
                <PersistantFormValuesProvider>
                  <Dashboard
                    key={`dashboard-${selectedDashboard.id}`}
                    dashboard={selectedDashboard}
                  />
                </PersistantFormValuesProvider>
              </div>
            ) : (
              <ListSkeleton />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
