import { LogLevel } from "@azure/msal-browser";

export const authConfig = {
  mode: process.env.REACT_APP_AUTH, // Set to "b2c" if using B2C
  appInsights: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
  },
};

if (authConfig.mode === "b2c") {
  // console.log("B2C Auth enabled");

  // check if B2C environment variables are set
  if (!process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID) {
    throw new Error("REACT_APP_AZURE_AD_B2C_CLIENT_ID is not set in .env");
  }
  if (!process.env.REACT_APP_AZURE_AD_B2C_PRIMARY_USER_FLOW) {
    throw new Error(
      "REACT_APP_AZURE_AD_B2C_PRIMARY_USER_FLOW is not set in .env"
    );
  }
  if (!process.env.REACT_APP_AZURE_AD_B2C_TENANT_ID) {
    throw new Error("REACT_APP_AZURE_AD_B2C_TENANT_ID is not set in .env");
  }
  if (!process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME) {
    throw new Error("REACT_APP_AZURE_AD_B2C_TENANT_NAME is not set in .env");
  }
} else {
  // console.log("Legacy Auth enabled");
  // check if legacy environment variables are set
}

// check if the environment variables are set
if (!process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY) {
  throw new Error(
    "REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY is not set in .env"
  );
}

/**
 * Enter here the user flows and custom policies for your B2C application
 * Tutorial: https://learn.microsoft.com/en-us/azure/active-directory-b2c/configure-authentication-sample-react-spa-app
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signIn: process.env.REACT_APP_AZURE_AD_B2C_PRIMARY_USER_FLOW,
    // signUpSignIn: "b2c_1_susi_v2",
    // forgotPassword: "b2c_1_forgotpassword",
    // editProfile: "b2c_1_edit_profile",
  },
  authorities: {
    signIn: {
      authority: `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_AD_B2C_PRIMARY_USER_FLOW}`,
    },
    // signUpSignIn: {
    //     authority: 'https://unitassoftwaredev.b2clogin.com/unitassoftwaredev.onmicrosoft.com/b2c_1_susi_v2',
    // },
    forgotPassword: {
      authority: `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/B2C_1_RESET`,
    },
    // editProfile: {
    //     authority: 'https://unitassoftwaredev.b2clogin.com/unitassoftwaredev.onmicrosoft.com/b2c_1_edit_profile',
    // },
  },
  authorityDomain: `${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com`,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  api: {
    // endpoint: "/api/farms-get",
    scopes: {
      read: [
        `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID}/Api.Access`,
        // "https://unitassoftwaredev.onmicrosoft.com/react-pwa-farm-api-dev/Farms.Read",
      ],
      write: [
        `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID}/Api.Access`,
        // "https://unitassoftwaredev.onmicrosoft.com/react-pwa-farm-api-dev/Farms.Write",
      ],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    ...protectedResources.api.scopes.read,
    ...protectedResources.api.scopes.write,
  ],
};
