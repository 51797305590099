import useFetch from "./useFetch";
import { useCallback, useEffect, useState } from "react";
import { sqlDateObjectFromServerTZ } from "helpers/dateUtilities";
import { protectedResources } from "authConfig";
import { toast } from "react-toastify";

type useFarmScheduleGetManyProps = {
  enabled?: boolean;
};
export const useFarmScheduleGetMany = ({
  enabled = true,
}: useFarmScheduleGetManyProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch farm schedules");
    },
  });

  const [schedules, setSchedules] = useState([]);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/schedules-get");

    // Change all server datetimes to local date object, making comparison easier going forward
    const updatedData = (data?.d ?? []).map(
      (record: { CompletedDate: any }) => {
        return {
          ...record,
          _CompletedDate: sqlDateObjectFromServerTZ(record.CompletedDate),
        };
      }
    );

    setSchedules(updatedData);

    return updatedData;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, schedules };
};
