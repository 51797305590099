import { Outlet, useLocation } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import classNames from "classnames";
import Sidebar from "components/Sidebar";
import Navbar from "components/Navbar";
import { localDate } from "helpers/dateUtilities";
import Settings from "components/Settings";
import { useTheme } from "hooks/useTheme";
import { useEffect, useState } from "react";
import { AppVersion } from "App";

export default function RootLayout() {
  const location = useLocation();

  const [showSettings, setShowSettings] = useState(false);

  const [theme, setTheme] = useTheme();

  /**
   * Handle service worker update available event
   * without having to refresh the page
   * @see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
   */
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update()));
    }
  }, [location]);

  return (
    <div>
      <ScrollToTop />
      <div id="app" className={classNames(theme)}>
        <div
          className={classNames(
            "bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-200 min-h-screen font-sans text-base flex flex-col antialiased overflow-x-hidden"
          )}
        >
          <Navbar theme={theme} />
          <Sidebar
            position="left"
            theme={theme as any}
            setTheme={setTheme as any}
            setShowSettings={setShowSettings}
          />
          <Settings
            open={showSettings}
            setOpen={setShowSettings}
            settings={[
              {
                id: "theme",
                value: theme,
                setValue: setTheme as any,
                label: "Theme",
                type: "toggle",
                checkedValue: "dark",
                uncheckedValue: "light",
              },
            ]}
          />
          <Outlet />
          <div className="py-2 flex items-center justify-center">
            <p className="text-xs text-gray-400">
              &copy; Unitas Software {localDate().getFullYear()}.{AppVersion}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
