import Card, { CardTitle } from "components/Card";
import { Alert } from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import Button from "components/placement/Button";
import {
  PageApiPermissionMapRecordSchema,
  PageApiPermissionMapSchema,
  PageFormPermissionMapRecordSchema,
  PageFormPermissionMapSchema,
  RoleMenuFormSchema,
  rolePermissionsMutateSchema,
  RolePermissionsMutateSchema,
  useRoleGetOne,
  useRoleMenuForm,
  useRolePermissionsMutate,
} from "hooks/useRole";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "components/placement/Form";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { MenuItemSchema, useMenuGetOneById } from "hooks/useMenu";
import { Checkbox } from "components/placement/Checkbox";
import { useUser } from "hooks/useUser";
import { type CheckedState } from "@radix-ui/react-checkbox";
import { isNullEmptyOrWhitespace, tryInvalidateApiCache } from "helpers/common";
import MenuFormAssociatedPermissions from "./_associated_permissions";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "components/ui/collapsible";
import { ChevronRightIcon } from "lucide-react";

function toggleAssociatedPermissions(
  permissionMap: PageApiPermissionMapSchema | PageFormPermissionMapSchema,
  associatedProperty: Exclude<
    | keyof PageApiPermissionMapRecordSchema
    | keyof PageFormPermissionMapRecordSchema,
    "pagePermissionId" | "name"
  >,
  checkedPermissionId: string,
  checked: CheckedState,
  mutatablePermissions: Record<string, boolean>
) {
  // Get all 'associatedProperty' permissions associated with this page permission
  const filteredMap = (permissionMap as any).filter(
    (map: any) => map.pagePermissionId === checkedPermissionId
  );
  const associatedPermissions = filteredMap.map(
    // TODO: Fix this any type, not sure how to best handle it yet
    (map: any) => map[associatedProperty]
  );
  if (associatedPermissions) {
    associatedPermissions.forEach((permissionId: any) => {
      if (checked) {
        // Enable all 'associatedProperty' permissions
        mutatablePermissions[permissionId] = true;
      } else {
        // Try to disable the 'associatedProperty' permissions
        // But first, check if this apiPermissionId is required by other page permissions
        const otherPagePermissions = (permissionMap as any).filter(
          (map: any) =>
            // TODO: Fix this any type, not sure how to best handle it yet
            map[associatedProperty] === permissionId &&
            map.pagePermissionId !== checkedPermissionId
        );
        const required = otherPagePermissions.some(
          (map: any) => mutatablePermissions[map.pagePermissionId] === true
        );
        if (!required) {
          mutatablePermissions[permissionId] = false;
        }
      }
    });
  }
}

type RoleMenuFormProps = {
  roleId: string;
  menuId: string;
};
const RoleForm = ({ roleId, menuId }: RoleMenuFormProps) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const {
    data: roleMenuForm,
    isLoading: isLoadingRoleMenuForm,
    isFetched: isFetchedRoleMenuForm,
    error: errorRoleMenuForm, // TODO: display error to user
  } = useRoleMenuForm();

  const {
    data: roleData,
    isLoading: isLoadingRoles,
    isFetched: isFetchedRoles,
  } = useRoleGetOne({
    id: roleId,
  });

  const {
    data: menuData,
    isLoading: isLoadingMenus,
    isFetched: isFetchedMenus,
  } = useMenuGetOneById({
    enabled: !isNullEmptyOrWhitespace(user),
    id: menuId,
  });

  const { mutate } = useRolePermissionsMutate({
    onSuccess: async () => {
      toast.success("Role menu permissions updated successfully.");

      await tryInvalidateApiCache("/api/menus-get");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<RolePermissionsMutateSchema>({
    defaultValues: {
      id: roleId,
      permissions: {} as RolePermissionsMutateSchema["permissions"],
    },
    resolver: zodResolver(rolePermissionsMutateSchema),
  });
  const resetForm = reactForm.reset;

  const availablePermissions = React.useMemo(() => {
    const menuPermissions = collectPermissionIds(menuData?.menuItems ?? []);
    const apiPermissions =
      roleMenuForm?.pageApiPermissionMap?.map((map) => map.apiPermissionId) ??
      [];
    const formPermissions =
      roleMenuForm?.pageFormPermissionMap?.map((map) => map.formPermissionId) ??
      [];
    return [...menuPermissions, ...apiPermissions, ...formPermissions];
  }, [menuData, roleMenuForm]);

  React.useEffect(() => {
    if (roleData) {
      const newFormData = {
        id: roleData.id,
        // { permissionId1: true, permissionId2: true }
        permissions: availablePermissions.reduce(
          (acc: Record<string, boolean>, permissionId) => {
            acc[permissionId] = roleData.permissions.some(
              (p) => p.id === permissionId
            );
            return acc;
          },
          {}
        ),
      };
      resetForm(newFormData);
    }
  }, [roleData, resetForm, availablePermissions]);

  if (
    isLoadingRoleMenuForm ||
    !isFetchedRoleMenuForm ||
    isLoadingRoles ||
    isLoadingMenus ||
    !isFetchedRoles ||
    !isFetchedMenus
  ) {
    return <FieldsetSkeleton />;
  }

  if (!roleData) {
    return <Alert theme="danger">Role not found.</Alert>;
  }

  if (!menuData) {
    return <Alert theme="danger">Menu not found.</Alert>;
  }

  if (!roleMenuForm) {
    return <Alert theme="danger">Role menu form not found.</Alert>;
  }

  const onSubmit = async (data: RolePermissionsMutateSchema) => {
    console.log("data", data);
    await mutate(data);
  };

  const handleClickCancel = () => {
    navigate("/admin/config/roles");
  };

  if (errorRoleMenuForm) {
    return <Alert theme="danger">Error loading role menu form.</Alert>;
  }

  return (
    <>
      <Form {...reactForm}>
        <form
          className="mt-4 space-y-4"
          onSubmit={reactForm.handleSubmit(onSubmit)}
          noValidate
        >
          <Card>
            <CardTitle>
              <div>
                Edit Menu Permission(s) for role{" "}
                <span className="italic">{roleData.name}</span>
              </div>
            </CardTitle>
            <nav className="pt-4">
              {!!menuData.menuItems && (
                <MenuBuilder
                  reactForm={reactForm}
                  menuItems={menuData.menuItems}
                  roleMenuForm={roleMenuForm}
                />
              )}
            </nav>
          </Card>
          <div className="flex justify-between">
            <Button
              type="button"
              variant="default"
              size="sm"
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <div className="space-x-4">
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
};

export default RoleForm;

const MenuBuilder: React.FC<{
  reactForm: UseFormReturn<RolePermissionsMutateSchema>;
  menuItems: MenuItemSchema[];
  roleMenuForm: RoleMenuFormSchema;
}> = ({ reactForm, menuItems, roleMenuForm }) => {
  const formPermissions = reactForm.watch("permissions"); // Watch for changes in permissions

  const handleGroupCheckboxChange = (
    checked: CheckedState,
    parentMenuId: string
  ) => {
    const parentMenuItem = menuItems.find((item) => item.id === parentMenuId);
    if (!parentMenuItem) {
      return;
    }

    const permissionIds = collectPermissionIds(parentMenuItem.children ?? []);
    const newPermissions = Object.assign({}, formPermissions); // Ensure we are not mutating state directly
    for (const id of permissionIds) {
      if (checked === "indeterminate") {
        newPermissions[id] = true;
      } else {
        newPermissions[id] = checked;
      }
    }

    reactForm.setValue("permissions", newPermissions, {
      shouldValidate: true,
    });
  };

  const handlePagePermissionCheckboxChange = (
    checked: CheckedState,
    permissionId: string,
    toggleAssocPerms = true
  ) => {
    const newPermissions = Object.assign({}, formPermissions); // Ensure we are not mutating state directly
    if (checked) {
      newPermissions[permissionId] = true;
    } else {
      newPermissions[permissionId] = false;
    }

    // Toggle associated 'api' permissions
    if (toggleAssocPerms && roleMenuForm.pageApiPermissionMap) {
      toggleAssociatedPermissions(
        roleMenuForm.pageApiPermissionMap,
        "apiPermissionId",
        permissionId,
        checked,
        newPermissions
      );
    }

    // Toggle associated 'form' permissions
    if (toggleAssocPerms && roleMenuForm.pageFormPermissionMap) {
      toggleAssociatedPermissions(
        roleMenuForm.pageFormPermissionMap,
        "formPermissionId",
        permissionId,
        checked,
        newPermissions
      );
    }

    reactForm.setValue("permissions", newPermissions, {
      shouldValidate: true,
    });
  };

  const getCountCheckedPermissionsInGroup = (
    menuItems: MenuItemSchema[]
  ): number => {
    return menuItems.reduce((count, item) => {
      if (item.permissionId && formPermissions[item.permissionId] === true) {
        return count + 1;
      }
      if (item.children) {
        return count + getCountCheckedPermissionsInGroup(item.children);
      }
      return count;
    }, 0);
  };

  if (!menuItems) {
    return null;
  }

  return (
    <ul className="divide divide-y">
      {menuItems.map((item, index) => {
        const countCheckedPermissionsInGroup = item.children
          ? getCountCheckedPermissionsInGroup(item.children)
          : 0;
        const totalPermissionsInGroup = item.children
          ? collectPermissionIds(item.children).length
          : 0;
        const hasAssociatedPermissions =
          !!(
            item.permissionId
            // && (roleMenuForm.pageFormPermissionMap || roleMenuForm.pageApiPermissionMap)
          );

        return (
          <li key={item.id}>
            <Collapsible
              className="group/collapsible [&[data-state=open]>div>div>svg]:text-primary [&[data-state=open]>div>div>svg]:rotate-90"
              defaultOpen={index === 0}
            >
              {item.children && item.children.length ? (
                <>
                  <div className="flex flex-row items-center space-x-2 py-2">
                    <Checkbox
                      checked={
                        countCheckedPermissionsInGroup ===
                        totalPermissionsInGroup
                          ? true
                          : countCheckedPermissionsInGroup > 0
                          ? "indeterminate"
                          : false
                      }
                      onCheckedChange={(checked) =>
                        handleGroupCheckboxChange(checked, item.id)
                      }
                    />
                    <CollapsibleTrigger asChild>
                      <div className="flex items-center justify-between space-x-4 px-4">
                        <div className="space-y-1 leading-none">
                          {item.title}
                        </div>
                        <ChevronRightIcon className="transition-transform" />
                      </div>
                    </CollapsibleTrigger>
                  </div>
                  <CollapsibleContent>
                    <ul className="pl-6 pr-2">
                      <MenuBuilder
                        reactForm={reactForm}
                        menuItems={item.children}
                        roleMenuForm={roleMenuForm}
                      />
                    </ul>
                  </CollapsibleContent>
                </>
              ) : (
                <>
                  <div className="flex flex-row items-center space-x-2 my-2 text-[0.85rem]">
                    <Checkbox
                      checked={
                        !!item.permissionId &&
                        formPermissions[item.permissionId] === true
                      } // Use watched permissions
                      onCheckedChange={(checked) => {
                        if (!item.permissionId) {
                          return;
                        }

                        const confirmToggle = window.confirm(
                          "Would you like to toggle associated permissions?"
                        );

                        if (confirmToggle) {
                          handlePagePermissionCheckboxChange(
                            checked,
                            item.permissionId
                          );
                        } else {
                          handlePagePermissionCheckboxChange(
                            checked,
                            item.permissionId,
                            false
                          );
                        }
                      }}
                    />
                    <div className="space-y-1 leading-none">{item.title}</div>
                  </div>
                  {hasAssociatedPermissions && (
                    <Collapsible
                      className="group/collapsible [&[data-state=open]>div>div>svg]:text-primary [&[data-state=open]>div>div>svg]:rotate-90"
                      defaultOpen={index === 0}
                    >
                      <CollapsibleContent>
                        <div className="flex flex-row items-start space-x-3">
                          <MenuFormAssociatedPermissions
                            title="Form Permissions"
                            menuItem={item}
                            permissionMap={roleMenuForm.pageFormPermissionMap}
                            formPermissions={formPermissions}
                            reactForm={reactForm}
                            associatedProperty="formPermissionId"
                          />
                          <MenuFormAssociatedPermissions
                            title="API Permissions"
                            menuItem={item}
                            permissionMap={roleMenuForm.pageApiPermissionMap}
                            formPermissions={formPermissions}
                            reactForm={reactForm}
                            associatedProperty="apiPermissionId"
                          />
                        </div>
                      </CollapsibleContent>
                    </Collapsible>
                  )}
                </>
              )}
            </Collapsible>
          </li>
        );
      })}
    </ul>
  );
};

function collectPermissionIds(menuItems: MenuItemSchema[]): string[] {
  return menuItems.flatMap((item) => {
    const childPermissions = item.children
      ? collectPermissionIds(item.children)
      : [];
    return item.permissionId
      ? [item.permissionId, ...childPermissions]
      : childPermissions;
  });
}
