import { Checkbox } from "components/placement/Checkbox";
import { MenuItemSchema } from "hooks/useMenu";
import type {
  PageApiPermissionMapRecordSchema,
  PageApiPermissionMapSchema,
  PageFormPermissionMapRecordSchema,
  PageFormPermissionMapSchema,
  RolePermissionsMutateSchema,
} from "hooks/useRole";
import React from "react";
import { type CheckedState } from "@radix-ui/react-checkbox";
import { type UseFormReturn } from "react-hook-form";
import { isNullEmptyOrWhitespace } from "helpers/common";

function MenuFormAssociatedPermissions({
  title,
  menuItem,
  permissionMap,
  formPermissions,
  reactForm,
  associatedProperty,
}: {
  title: React.ReactNode;
  menuItem: MenuItemSchema;
  permissionMap:
    | PageApiPermissionMapSchema
    | PageFormPermissionMapSchema
    | undefined;
  formPermissions: Record<string, boolean>;
  reactForm: UseFormReturn<RolePermissionsMutateSchema>;
  associatedProperty: Exclude<
    | keyof PageApiPermissionMapRecordSchema
    | keyof PageFormPermissionMapRecordSchema,
    "pagePermissionId" | "name"
  >;
}) {
  const pageFilteredPermissionMap = (permissionMap as any)?.filter(
    (map: any) => map.pagePermissionId === menuItem.permissionId
  );

  const handlePermissionCheckboxChange = (
    checked: CheckedState,
    formPermissionId: string
  ) => {
    console.log("formPermissionId", formPermissionId, "checked", checked);
    const newPermissions = { ...formPermissions }; // Ensure we are not mutating state directly
    if (checked) {
      newPermissions[formPermissionId] = true;
    } else {
      newPermissions[formPermissionId] = false;
    }

    reactForm.setValue("permissions", newPermissions, {
      shouldValidate: true,
    });
  };

  if (isNullEmptyOrWhitespace(pageFilteredPermissionMap)) {
    return null;
  }

  return (
    <div className="pl-6 my-2 text-xs">
      <div className="text-gray-500">{title}:</div>
      <div className="ml-4 py-2 space-y-2">
        {pageFilteredPermissionMap.map((map: any) => (
          <div
            key={map.formPermissionId}
            className="flex items-center space-x-2"
          >
            <Checkbox
              checked={formPermissions[map[associatedProperty]] === true}
              onCheckedChange={(checked) =>
                handlePermissionCheckboxChange(checked, map[associatedProperty])
              }
            />
            <span>{map.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MenuFormAssociatedPermissions;
