import { protectedResources } from "authConfig";
import {
  IForm,
  IListOption,
  parseCustomListVariable,
} from "helpers/formUtilities";
import { parseJSON, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useFarmGetMany } from "hooks/useFarm";
import useFetch from "hooks/useFetch";
import { useUser } from "hooks/useUser";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";

export interface IFormContext {
  data: IForm[];
  isLoading: boolean;
  isFetched: boolean;
}

const FormContext = createContext({} as IFormContext);

const defaultData: IForm[] = [];

const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const { execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch form data");
    },
  });

  const { data: farms } = useFarmGetMany();

  const { isSignedIn } = useUser();

  const [data, setData] = useState<IForm[]>(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const customListOptions = useMemo(() => {
    const result = {
      farms: [] as IListOption[],
      houseNumbers: [] as IListOption[],
      flockDates: [] as IListOption[],
      flockHouses: [] as IListOption[],
    };
    for (const farm of farms) {
      result.farms.push({
        Id: farm.FarmCode.toString(),
        Text: `${farm.FarmCode} - ${farm.FarmName}`,
        Value: farm.FarmCode.toString(),
      });

      for (const house of farm.Houses) {
        result.houseNumbers.push({
          Id: `${house.HouseNumber}`,
          Text: `House ${house.HouseNumber}`,
          Value: `${house.HouseNumber}`,
          Parent: farm.FarmCode.toString(),
        });
      }

      for (const flockDate of Object.keys(farm.Flocks)) {
        result.flockDates.push({
          Id: `${farm.FarmCode.toLowerCase()}_${flockDate}`,
          Text: flockDate.toString(),
          Value: flockDate.toString(),
          Parent: farm.FarmCode.toString(),
        });
      }

      for (const [flockDate, flocks] of Object.entries(farm.Flocks)) {
        for (const flock of flocks) {
          result.flockHouses.push({
            Id: `${farm.FarmCode.toLowerCase()}_${flockDate}_${flock.HouseNumber.toString()}`,
            Text: flock.HouseLabel.toString(),
            Value: flock.HouseNumber.toString(),
            Parent: [farm.FarmCode, flockDate],
          });
        }
      }
    }

    return result;
  }, [farms]);

  const fetchData = useCallback(async () => {
    if (
      isNullEmptyOrWhitespace(customListOptions.farms) ||
      isNullEmptyOrWhitespace(customListOptions.houseNumbers) ||
      isNullEmptyOrWhitespace(customListOptions.flockDates) ||
      isNullEmptyOrWhitespace(customListOptions.flockHouses)
    ) {
      // Wait for custom list options to be populated
      return;
    }

    setIsLoading(true);

    const { data } = await execute("GET", "/api/forms-get");

    const newData: IForm[] = data?.d ?? defaultData;

    // Convert meta string to JSON
    // TODO: find a better way to do this
    newData.forEach((form) => {
      form.FormFields.forEach((field) => {
        // Parse metadata
        const parsedDisplay = parseJSON(field.Display?.toString());

        if (!isNullEmptyOrWhitespace(field.Required)) {
          field.Required = parseJSON(field.Required as string);
        }

        // Set metadata as JSON
        field.Display = parsedDisplay as any;

        // Set custom logic list options
        if (!!field.List) {
          const regex = /\${([^{}]+)}/i;
          const matched = regex.exec(field.List);
          let newListOptions: IListOption[] = [];
          if (matched?.[1] !== undefined) {
            const { ref } = parseCustomListVariable(matched[1]);

            if (ref === "farms") {
              newListOptions = customListOptions.farms;
            } else if (ref === "housenumbers") {
              newListOptions = customListOptions.houseNumbers;
            } else if (ref === "farm_flocks") {
              newListOptions = customListOptions.flockDates;
            } else if (ref === "farm_flock_houses") {
              newListOptions = customListOptions.flockHouses;
            }

            // Remove empty list options
            field.ListOptions = newListOptions.filter(
              (li) =>
                !isNullEmptyOrWhitespace(li.Id) &&
                !isNullEmptyOrWhitespace(li.Text) &&
                !isNullEmptyOrWhitespace(li.Value)
            );
          }
        }
      });
    });

    setData(newData);
    setIsLoading(false);
    setIsFetched(true);

    return newData;
  }, [
    customListOptions.farms,
    customListOptions.flockDates,
    customListOptions.flockHouses,
    customListOptions.houseNumbers,
    execute,
  ]);

  useEffect(() => {
    if (isSignedIn) {
      fetchData();
    }
  }, [isSignedIn, fetchData]);

  const contextValue = useMemo(
    () => ({
      data,
      isLoading,
      isFetched,
    }),
    [data, isLoading, isFetched]
  );

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  );
};

export { FormProvider as default, FormContext };
