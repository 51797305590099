import { Control } from "react-hook-form";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { useRef } from "react";
import classNames from "classnames";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "components/placement/Form";
import { MenuItemSchema } from "hooks/useMenu";

const DRAG_TYPE = "MENU_ITEM";

type MenuItemProps = {
  field: MenuItemSchema;
  index: number;
  parentName: string;
  move: (from: number, to: number) => void;
  control: Control<any>;
};

type DragItem = {
  index: number;
  type: string;
  parentName: string;
};

const MenuItem: React.FC<MenuItemProps> = ({
  field,
  index,
  parentName,
  move,
  control,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TYPE,
    item: { index, parentName },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop<DragItem>({
    accept: DRAG_TYPE,
    hover(item, monitor) {
      if (!ref.current) return;
      if (item.parentName !== parentName) return; // Only allow moving within the same parent
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      move(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={classNames(
        "border border-dashed rounded-sm px-2 py-2 w-full cursor-move hover:text-primary",
        isDragging ? "border-gray-300 bg-gray-100" : "border-transparent"
      )}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <FormField
        control={control}
        name={`${parentName}.${index}.title`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Title</FormLabel>
            <FormControl>
              <input
                {...field}
                className="block w-full rounded-sm border-gray-300"
                placeholder="Title"
              />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};

export default MenuItem;
