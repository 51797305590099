import MenuList from "components/config/menu/list";
import PageHeader from "components/PageHeader";
// import Button from "components/placement/Button";
// import { PlusCircleIcon } from "lucide-react";
// import { useNavigate } from "react-router-dom";

// TODO: Implement the create new menu page
const ConfigMenusPage = () => {
  // const navigate = useNavigate();

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Menu(s)</div>
          {/* <Button
            type="button"
            variant="primary"
            onClick={() => navigate("new")}
          >
            <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
          </Button> */}
        </div>
      </PageHeader>
      <div className="col-span-full">
        <MenuList />
      </div>
    </>
  );
};

export default ConfigMenusPage;
