import MenuForm from "components/config/menu/form";
import PageHeader from "components/PageHeader";
import { useParams } from "react-router-dom";

const ConfigMenuMutatePage = () => {
  const { id } = useParams();

  return (
    <>
      <PageHeader>
        <div className="text-xl">Menu</div>
      </PageHeader>
      <div className="col-span-full">
        <MenuForm id={id} />
      </div>
    </>
  );
};

export default ConfigMenuMutatePage;
